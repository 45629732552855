import React from 'react';
import AppRouter from './router'
import Spectra from 'spectra'
import './styles/main.scss'
import GlobalStyles from './parts/GlobalStyles'
import {ThemeProvider} from 'styled-components'
import {firebaseConfig} from '../firebaseConfig'
import photonTheme from 'app/theme'
// import Notification from 'photon/src/components/Notification'
import FullScreen from 'photon/src/components/FullScreen'
import WithAuth from 'fb/wrappers/WithAuth'

import store from './store'
import { Provider } from 'react-redux'

function App() {

  return (
    <Provider store={store}>
      <Spectra config={firebaseConfig}>
        <ThemeProvider theme={photonTheme}>
          <GlobalStyles />
          <WithAuth>
            <FullScreen>
              <AppRouter />
            </FullScreen>
          </WithAuth>
          {
            // <Notification />
          }
        </ThemeProvider>
      </Spectra>
    </Provider>
  );
}

export default App;
