const _colors = {
  // colors
  white: "#FFFFFF",
  offWhite: "#FCFCFC",
  darkGray: "gray",
  gray: "slategray",
  lightGray: "lightgray",
  black: "#222222",
  red: "tomato",
  orange: '#f5af19b3',
  purple: "#5268db",
  lightPurple: '#7d81e0',
  darkPurple: '#5d6ddc',
}

export default {
  colors: {
    ..._colors,
    //
    primary: '#7cad65',
    secondary: _colors.gray,
    accent: _colors.darkPurple,
    warning: _colors.orange,
  },
  media: [
    "600px", // mobile max
    "1024px", // tablet max
    "1300px", // laptop max
    "1500px", // desktop max
    "2000px", // xl max
  ]
}
