import React from 'react'
import Image from 'photon/src/components/Image'
import { withTheme } from 'styled-components';

function Logo({link, inline, theme}) {

  if (link) {
    return (
      <a href={'/'}>
        <Image {...(theme.logo)} />
      </a>
    );
  }

  return (
    <Image {...(theme.logo)} />
  );
}

export default withTheme(Logo);
