import styled from 'styled-components'
import Box from '../Box'

export const FullScreenWrapper = styled(Box).attrs(props => ({
  className: `photon-fullscreen`,
  ml: props.ml || '0px',
  mr: props.mr || '0px',
  flex: true,
}))`
  flex: 1;
  flex-direction: column;
  align-items: stretch;

  ${props => props.centerContent &&
    `justify-content: center;`
  }

  min-width: 100vw;
  min-height: 100vh;
`
