import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
// import ScrollToTopAuto from './lib/components/ScrollToTopAuto'
import loadable from '@loadable/component'
import Header from 'app/parts/Header'
import Footer from 'app/parts/Footer'
import {useUserAuthChange} from 'app/lib/hooks/useUserAuthChange'
// import {currentUser} from 'fb/auth'
const _screens = './screens'
// Routes
const MyWalks = loadable(() => import(`${_screens}/MyWalks`))
const Home = loadable(() => import(`${_screens}/Home`))
const Food = loadable(() => import(`${_screens}/Food`))
const MyPets = loadable(() => import(`${_screens}/MyPets`))
const Pet = loadable(() => import(`${_screens}/Pet`))
const EditPet = loadable(() => import(`${_screens}/EditPet`))
const NewPet = loadable(() => import(`${_screens}/NewPet`))
const Camera = loadable(() => import(`${_screens}/Camera`))

export default function AppRouter() {
  // const user = currentUser()
  const user = useUserAuthChange()
  return (
    <Router>
      {user && <Header />}
      <Switch>

        <Route exact path="/">
          <Home />
        </Route>

        <Route exact path="/my-walks">
          <MyWalks />
        </Route>

        <Route exact path="/my-pets">
          <MyPets />
        </Route>

        <Route exact path="/pet/:pid">
          <Pet />
        </Route>

        <Route exact path="/edit-pet/:pid">
          <EditPet />
        </Route>

        <Route exact path="/new-pet">
          <NewPet />
        </Route>

        <Route exact path="/camera">
          <Camera />
        </Route>

        <Route exact path="/food">
          <Food />
        </Route>

      </Switch>
      {user && <Footer />}
    </Router>
  );
}
