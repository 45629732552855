import styled from 'styled-components'
import Container from 'photon/src/components/Container'

import {getTheme, respondTo} from 'photon'

// const colors = getTheme('colors', {})
const media = getTheme('media', {})

export const FooterWrapper = styled.footer`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  z-index: 9999;
  background: rgba(255, 255, 255, 0.95);
`

export const FooterInner = styled(Container)`
  padding: 5px 10px;
  align-items: center;
  justify-content: space-around;

  ${respondTo(media[0])} {
    flex-direction: row;
  }

  button {
    margin: 0;
    padding: 0.25em 0.5em;
  }
`
