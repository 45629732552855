import React, {useEffect, useState} from 'react';
import {useHistory} from 'react-router'
// import Link from 'photon/src/components/Link'
import Button from 'photon/src/components/Button'
import Image from 'photon/src/components/Image'
import dogWalk from 'app/assets/dog walk.svg'
import stats from 'app/assets/stats.svg'
// import camera from 'app/assets/camera.svg'
import dogFood from 'app/assets/dog-food.svg'
//
import dogWalkOutline from 'app/assets/dog walk outline.svg'
import statsOutline from 'app/assets/stats outline.svg'
// import cameraOutline from 'app/assets/camera outline.svg'
import dogFoodOutline from 'app/assets/dog-food outline.svg'
import {
  FooterInner,
  FooterWrapper,
} from './styles'

function Footer() {

  const hist = useHistory()

  const [path, setPath] = useState(hist.location.pathname)

  useEffect(() => {
    if (hist.location.pathname !== path) {
      hist.push(path)
    }
  }, [hist, path])


  return (<FooterWrapper>
    <FooterInner flex={1}>
      {
        // <Button unstyled onClick={() => hist.push('/camera')}>
        //   <Image src={('/camera' === hist.location.pathname
        //     ? camera
        //     : cameraOutline)} widths={['2em']} />
        // </Button>
      }

      <Button unstyled onClick={() => setPath('/food')}>
        <Image src={('/food' === path
          ? dogFood
          : dogFoodOutline)} widths={['2.5em']} />
      </Button>

      <Button unstyled onClick={() => setPath('/')}>
        <Image src={('/' === path
          ? dogWalk
          : dogWalkOutline)} widths={['2em']} />
      </Button>

      <Button unstyled onClick={() => setPath('/my-walks')}>
        <Image src={('/my-walks' === path
          ? stats
          : statsOutline)} widths={['2em']} />
      </Button>
    </FooterInner>
  </FooterWrapper>);
}

export default Footer
