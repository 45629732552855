import { useState, useEffect, useCallback } from 'react';
import { onUserAuth } from 'fb/auth'

export const useUserAuthChange = () => {

  const [user, setUser] = useState()

  const listen = useCallback(() => {
    onUserAuth((user) => setUser(user))
  }, [])

  useEffect(() => {
    if (undefined === user) listen()
  }, [user, listen])

  return user
}
