import styled from 'styled-components'
import Box from 'photon/src/components/Box'

export const DrawerContainer = styled(Box)`
  display: inline-block;
`

export const DrawerWrapper = styled(Box)`
  top: 0;
  right: 0;
  height: 100vh;
  z-index: 999999;
  display: flex;
  position: fixed;
  overflow: hidden;
  align-items: stretch;
  flex-direction: column;
  justify-content: flex-start;
  transition: all .6s ease-in-out;
  opacity: ${props => props.active ? 1 : 0};
  width: ${props => props.active ? `280px` : `0`};
  background: ${props => props.theme.colors.white};
  box-shadow: inset 0 0 1em ${props => props.theme.colors.lightGray};
`

export const DrawerClose = styled(Box)`
  width: 2em;
  height: 2em;
  cursor: pointer;
  background: transparent;
  align-self: flex-end;
  margin: .5em .5em 0 0;
`

export const DrawerContent = styled(Box)`
  padding: 2em 1em 0.5em;
  position: relative;
`

export const DrawerToggle = styled(Box)`
  width: 2em;
  height: 2em;
  cursor: pointer;
  background: transparent;
`
