import styled from 'styled-components'
import Box from 'photon/src/components/Box'
import Container from 'photon/src/components/Container'

export const HeaderWrapper = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: ${props => props.theme.colors.white};
  z-index: 9999;
`

export const HeaderInner = styled(Container)`
  padding: .5em;
  justify-content: space-between;
`

export const LogoWrapper = styled(Box)`

`
