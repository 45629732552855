
import styled, {css} from 'styled-components'
import {getTheme} from 'photon'

export const ButtonWrapper = styled.button.attrs(props => ({
  className: `photon-button`
}))`
  display: ${props => true === props.flex ?
    `flex` :
    `inline-block`
  };

  ${css(getTheme('button', ''))}

  &:hover {
    ${css(getTheme('buttonHover', ''))}
  }

  &:active {
    ${css(getTheme('buttonActive', ''))}
  }

  ${props => props.primary &&
    css(getTheme('primaryButton', ''))
  }

  ${props => props.secondary &&
    css(getTheme('secondaryButton', ''))
  }

  ${props => props.warning &&
    css(getTheme('warningButton', ''))
  }

  ${props => props.unstyled &&
    css(getTheme('unstyledButton', ''))
  }

  ${props => props.pt && `padding-top: ${props.pt};`}
  ${props => props.pl && `padding-left: ${props.pl};`}
  ${props => props.pr && `padding-right: ${props.pr};`}
  ${props => props.pb && `padding-bottom: ${props.pb};`}

  ${props => props.mt && `margin-top: ${props.mt};`}
  ${props => props.ml && `margin-left: ${props.ml};`}
  ${props => props.mr && `margin-right: ${props.mr};`}
  ${props => props.mb && `margin-bottom: ${props.mb};`}

  box-sizing: border-box;
  cursor: pointer;
`
