import React from 'react';
// import {useMenu} from 'wpapi/hooks/useMenu'
import Link from 'photon/src/components/Link'
import {signOut} from 'fb/auth'

import {
  MenuWrapper,
  MenuItem,
  ItemsWrapper,
  // MenuToggle
} from 'wpapi/lib/components/Menu/styles'

function Menu({location, stacked}) {
  location = location || 'primary'
  // const hist = useHistory()
  // const menu = useMenu(location)
  const menu = [{
    title: `Log <span role="img"aria-label="spray emoji"style={{fontSize: '2.5em'}}>💦</span> & <span role="img"aria-label="poop emoji"style={{fontSize: '2.5em'}}>💩</span>`,
    url: '/'
  },
  {
    title: 'My Walks',
    url: '/my-walks'
  },
  {
    title: 'My Pets',
    url: '/my-pets'
  }]

  return (
    <MenuWrapper className={`menuWrapper location-${location}`}>
      <ItemsWrapper stacked={!!stacked}>
        {menu && menu.map((item, idx) => (
          <MenuItem stacked={!!stacked} key={idx}>
            <Link
            dangerouslySetInnerHTML={{__html: item.title}}
            url={item.url} />
          </MenuItem>
        ))}
        <MenuItem stacked={!!stacked}>
          <Link url={'/'} onClick={signOut}>
            Log Out
          </Link>
        </MenuItem>
      </ItemsWrapper>
    </MenuWrapper>
  );
}

export default Menu;
