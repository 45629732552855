import loadingIcon from './assets/loading.svg'
import logoSrc from 'app/assets/logo/logo-wht-bg.png'
import variables from './variables'
import typography from './typography'
import forms from './forms'

export default {
  ...variables,
  contentWidths: [
    "100%", // mobile
    "95%", // tablet max
    "960px", // laptop max
    // "", // desktop max
    // "", // xl max
  ],
  loadingIcon: loadingIcon,
  logo: {
    src: logoSrc,
    widths: ['116px', '130px', '140px'],
    alt: 'Photon Software logo',
    className: 'app-logo',
    width: 140,
    height: 49,
  },
  placeholder: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#d9dff1',
    opacity: 0.6,
  },
  //
  typography,
  // Form Elements
  ...forms
}
