import styled from 'styled-components'
import Box from 'photon/src/components/Box'

export const MenuWrapper = styled(Box)`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  position: relative;
`;

export const ItemsWrapper = styled.ul`
  flex: 1;
  padding: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;

  ${props => true === props.stacked && `
      flex-direction: column;
      align-items: stretch;
    `
  }
`;

export const MenuItem = styled.li`
  margin: 0 56px 0 0;
  list-style: none;

  ${props => props.stacked ? `
    margin: 1em 0 0 0;
  ` : `
    &:last-child {
      margin: 0;
    }
  `}

  a {
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    text-align: center;
    color: ${props => props.theme.colors.gray};
    text-decoration: none;
    &:hover {
      color: ${props => props.theme.colors.purple};
    }
  }
`;
