import { createSlice } from '@reduxjs/toolkit'

export const petsSlice = createSlice({
  name: 'pets',
  initialState: {
    pets: [],
    activePet: null,
    index: 0,
  },
  reducers: {
    changePet: (state, action) => {
      const {index} = action.payload;
      if (index !== state.index) {
        return {
          ...state,
          index,
          activePet: state.pets[index]
        }
      }
    },
    setPets: (state, action) => {
      state.pets = action.payload;
      state.activePet = state.pets[state.index]
    },
    changeIndex: (state, action) => {
      state.index = action.payload;
      state.activePet = state.pets[state.index]
    },
  },
})

// Action creators are generated for each case reducer function
export const { changePet, setPets, changeIndex } = petsSlice.actions

export default petsSlice.reducer
