import variables from './variables'

export default {
  input: {
    lineHeight: 1.5,
    padding: '0.5em',
    width: '100%',
    marginBottom: '1em',
    border: `0.125em solid ${variables.colors.lightGray}`,
    borderRadius: '0.25em',
    outline: `0`,
  },
  textarea: {
    minHeight: '10em'
  },
  button: {
    fontSize: 'inherit',
    lineHeight: 1.35,
    padding: '1em 2em',
    width: 'auto',
    margin: '1em 0',
    borderWidth: `0.0125em`,
    borderStyle: `solid`,
    borderRadius: '0.25em',
    outline: 0,
    transition: `all .2s ease-in-out`,
    color: variables.colors.white,
    backgroundColor: variables.colors.purple,
  },
  buttonHover: {
    // boxShadow: `0 0 0.5em ${variables.colors.lightPurple}`,
  },
  buttonActive: {
     transform: 'scale(0.95)'
  },
  primaryButton: {
    borderColor: variables.colors.primary,
    backgroundColor: variables.colors.primary,
    color: variables.colors.white,
  },
  secondaryButton: {
    borderColor: variables.colors.primary,
    backgroundColor: variables.colors.white,
    color: variables.colors.primary,
  },
  warningButton: {
    borderColor: variables.colors.red,
    backgroundColor: variables.colors.red,
    color: variables.colors.white,
  },
  unstyledButton: {
    borderColor: 'transparent',
    backgroundColor: 'transparent',
    color: variables.colors.primary,
    padding: '0.75em 1em'
  },
}
