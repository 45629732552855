export const firebaseConfig = {
  apiKey: "AIzaSyDZq-mzYX0ncqgpL8Lka1ddUJ0GyzUyeZQ",
  authDomain: "pet-buying-experience.firebaseapp.com",
  databaseURL: "https://pet-buying-experience.firebaseio.com",
  projectId: "pet-buying-experience",
  storageBucket: "pet-buying-experience.appspot.com",
  messagingSenderId: "1011065964355",
  appId: "1:1011065964355:web:34bf338f29dee15a5991f6",
  measurementId: "G-GGSN2TS0VB"
};
