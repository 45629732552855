
/**
 * Homepage template
 *
 * @package photon
 */
import React, {useState, useEffect, useCallback} from 'react';
import Loader from 'photon/src/components/Loader'
import FullScreen from 'photon/src/components/FullScreen'
import {onUserAuth} from 'fb/auth'

export default ({children}) => {

  const [user, setUser] = useState()

  const listen = useCallback(() => {
    onUserAuth((user) => setUser(user))
  }, [])

  useEffect(() => {
    if (undefined === user) {
      listen()
    }
  }, [user, listen])

  return (undefined === user ?
    <FullScreen flexBox={{
      alignItems: 'center',
      justifyContent: 'center'
    }}>
      <Loader />
    </FullScreen> :
    <>
    {children}
    </>
  );
}
