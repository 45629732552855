/**
 * Header
 */
import React from 'react';
import Logo from 'photon/src/components/Logo'
import Drawer from 'photon/src/contexts/Drawer'
import RespondTo from 'photon/src/components/RespondTo'
import {
  HeaderWrapper,
  LogoWrapper,
  HeaderInner,
} from './styles'
import HeaderMenu from 'app/lib/components/HeaderMenu'

function Header(props) {

  const drawerHeaderMenu = () => <Drawer><HeaderMenu stacked /></Drawer>

  return (
    <>
      <HeaderWrapper>
        <HeaderInner flex={1}>
          <LogoWrapper>
            <Logo link />
          </LogoWrapper>
          <RespondTo
            mobile={drawerHeaderMenu}
            tablet={drawerHeaderMenu}
            desktop={() => <HeaderMenu />}
          />
        </HeaderInner>
      </HeaderWrapper>
    </>
  );
}

export default Header
